import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import EditBtn from "../../../../commonComponents/EditBtn";
import PopUPCms from "../../../../commonComponents/PopUPCms";
import Backdrop from "../../../../commonComponents/PopUPCms/BackDrop";
import { SuperSaverBtn } from "../../../../commonComponents/SuperSaverBtn";
import NestedListView from "../../../../components/NestedListView";
import PopupTargetTax from "../../../../components/PopupTargetTax";
import {
  SuperSaverDashboardService,
  getSuperSaverUser,
  getTargetTax,
  getUserDocument,
  getUserIncome,
} from "../../../../Services/SuperSaver";
import { getAuthToken } from "../../../../Services/SuperSaverClientChat";
import { GetCRYPTO_CURRENCYTax } from "../../../../utils/capitalGainTaxCalc";
import { convertNumberIntoINR } from "../../../../utils/ConvertNumnerIntoIndianCurrency";
import { getTaxBySalary } from "../../../../utils/taxCalc";
import { actions } from "./store/action";
import {
  ProfileBox,
  ProfileStyle,
  UserInfo,
  UserProfileForAdvisorStyled,
} from "./UserProfileForAdvisor.styled";
import { getUpdatedTax, setCapitalGainTaxFn, setIncomeAndTaxFn } from "./utils";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import {
  cookDataForNestedListView,
  getFormattedDateAllWithMonthName,
} from "../../../../utils/taxUtils";
import { SuperSaverUserProfileApi } from "../../../../Services/SuperSaverUserProfileService";
import LoaderPage from "../../../../commonComponents/LoaderPage";
import { checkPreviousFinancialYear } from "../../../../utils/FinancialYearUtils";
import LayoutHOC from "../../../../components/UserSideBar";
import { useQuery } from "../../../../utils/routeUtils";

const HELPER_TEXTS = {
  expertTaxTitle: "Optimized Tax by Expert ",
  processing: "Processing...",
  projectTaxDateText:
    "Note: Final Income Tax will be generated on Mar 31, 2023.",
};

const INCOME_TYPES = {
  SALARIED: "Total Salary Income",
  CAPITAL_GAIN: "Total Capital Gain Income",
  BUSINESS_PROFESSION: "Total Business Profession Income",
  CRYPTO_CURRENCY: "Total Crypto Currency Income",
  OTHERS: "Other Incomes",
};

const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);

const TAX_TYPES = {
  "Salary,Business & Profession,Other":
    "Tax on Salary, Business & Profession, Others",
  "Business & Profession,Other": "Tax on Business & Profession, Other",
  "Capital Gain": "Tax on Capital Gain",
  "Crypto Currency": "Tax on Crypto Currency",
};

const UserProfileForAdvisor = () => {
  const state = useLocation();
  const params = useParams();
  const userIdFromQueryParam = useQuery().get("user_id") || undefined;
  const userIdFromURL = params.userId;
  const userId = (userIdFromURL !== undefined ? userIdFromURL : userIdFromQueryParam) || state?.state?.item?.user_id;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [targeted_tax, setTargetedtax] = useState(
    state?.state?.item?.targetTax !== undefined
      ? state?.state?.item?.targetTax
      : "To Be Calculated."
  ); 
  const { financialYearList } = useSelector((state) => state.cmsReducer);
  const [loader, setLoader] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [ExpireStatus, setExpireStatus] = useState(
    (state?.state?.item?.subscription_status) || (state?.state?.subscription_status)
  );

  const [targetedTaxExternalId, setTargetedTaxExternalId] = useState();
  const fyObj = useSelector((state) => state.LayoutReducer.F_Y_OBJ);
  const [selectedFy, setSelectedFy] = useState({});

  const incomeData = useSelector((state) => state.TaxReducer.incomeArray);
  const totalDeduction = useSelector((state) => state.TaxReducer.deduction);

  useEffect(() => {
    handleFYChange({
      label: fyObj.financialYear,
      value: fyObj.financialYearId,
    });
  }, [fyObj]);

  useEffect(() => {
    setLoader(true);
    (async () => {
      const UserID = userId;
      const data = await getTargetTax(UserID);
      const fYId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
      const response =
        UserID &&
        (await SuperSaverUserProfileApi.getUserDetailsByUserId(UserID, fYId));
      const userDetails = response.data;
      setUserDetails(userDetails);
      setTargetedtax(
        data.targeted_tax !== undefined
          ? data.targeted_tax
          : "To Be Calculated."
      );
      setTargetedTaxExternalId(data.external_id);
      setLoader(false);
    })();
  }, []);

  const handleFYChange = (value) => {
    setSelectedFy(value);
  };
  const [salary, setSalary] = useState(0);
  const [targetedTax, setTargetedTax] = useState({});
  const [capitalGainIncomeArr, setCapitalGainIncomeArr] = useState([]);
  const [STCG_DEBT_TOTAL_AMOUNT, setSTCG_DEBT_TOTAL_AMOUNT] = useState(0);
  const [capitalGainTax, setCapitalGainTax] = useState(0);
  const [cryptoCurrency, setCryptoCurrency] = useState(0);
  const [
    businessProfessionOtherIncomeObj,
    setBusinessProfessionOtherIncomeObj,
  ] = useState({
    BusinessProfessionIncome: 0,
    OtherIncome: 0,
    label: [],
  });
  const dispatch = useDispatch();
  const [tax, settax] = useState(
    getTaxBySalary(
      salary,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
        businessProfessionOtherIncomeObj.OtherIncome
    )
  );
  const [transaction, setTransaction] = useState([]);
  const [calculatedTax, setCalculatedTax] = useState([]);

  const [arrIncome, setArrIncome] = useState([]);
  const [taxObj, setTaxObj] = useState(
    getTaxBySalary(
      salary,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
        businessProfessionOtherIncomeObj.OtherIncome
    )
  );

  useEffect(() => {
    let taxObj = getTaxBySalary(
      salary,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
        businessProfessionOtherIncomeObj.OtherIncome
    );

    setTaxObj({ ...taxObj });
  }, [
    salary,
    STCG_DEBT_TOTAL_AMOUNT,
    totalDeduction,
    businessProfessionOtherIncomeObj.BusinessProfessionIncome,
    businessProfessionOtherIncomeObj.OtherIncome,
  ]);

  useEffect(() => {
    setCapitalGainTaxFn(
      salary,
      businessProfessionOtherIncomeObj,
      settax,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      arrIncome,
      setTargetedTax,
      setCapitalGainTax,
      capitalGainIncomeArr,
      incomeData,
      incomeData.income_details
    );
  }, [
    salary,
    businessProfessionOtherIncomeObj.BusinessProfessionIncome,
    businessProfessionOtherIncomeObj.OtherIncome,
    totalDeduction,
    STCG_DEBT_TOTAL_AMOUNT,
    capitalGainIncomeArr,
    incomeData,
    arrIncome,
    incomeData.income_details,
  ]);

  const incomeTypes = {
    SALARIED: "SALARIED",
    CAPITAL_GAIN: "CAPITAL_GAIN",
    STCG_DEBT: "STCG_DEBT",
    CRYPTO_CURRENCY: "CRYPTO_CURRENCY",
  };

  const setBusinessProfessionOtherIncomeObjFn = (value) => {
    setBusinessProfessionOtherIncomeObj(value);
  };

  useEffect(() => {
    setIncomeAndTaxFn({
      incomeData: incomeData,
      incomeTypes: incomeTypes,
      setSalary: setSalary,
      setCapitalGainIncomeArr: setCapitalGainIncomeArr,
      setSTCG_DEBT_TOTAL_AMOUNT: setSTCG_DEBT_TOTAL_AMOUNT,
      arrIncome: arrIncome,
      setCryptoCurrency: setCryptoCurrency,
      businessProfessionOtherIncomeObj: businessProfessionOtherIncomeObj,
      setBusinessProfessionOtherIncomeObj:
        setBusinessProfessionOtherIncomeObjFn,
    });
  }, [incomeData, incomeData.income_details]);

  useEffect(() => {
    const tax =
      taxObj &&
      getUpdatedTax(
        businessProfessionOtherIncomeObj,
        salary,
        totalDeduction,
        STCG_DEBT_TOTAL_AMOUNT,
        arrIncome,
        taxObj
      );

    setCalculatedTax([...tax]);
  }, [
    businessProfessionOtherIncomeObj.BusinessProfessionIncome,
    businessProfessionOtherIncomeObj.OtherIncome,
    businessProfessionOtherIncomeObj.label,
    salary,
    incomeData,
    arrIncome,
    taxObj,
  ]);

  let cryptoTax = GetCRYPTO_CURRENCYTax(cryptoCurrency);

  useEffect(() => {
    dispatch({
      type: actions.SET_CURRENT_TAX,
      data: {
        currentTax:
          parseInt(
            (tax == undefined
              ? 0
              : tax &&
                (salary +
                  businessProfessionOtherIncomeObj.BusinessProfessionIncome +
                  businessProfessionOtherIncomeObj.OtherIncome >
                250000
                  ? tax.totalTax
                  : 0) +
                  capitalGainTax +
                  cryptoTax
            ).toFixed(0)
          ) > 12500
            ? parseInt(
                (tax == undefined
                  ? 0
                  : tax &&
                    (salary +
                      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
                      businessProfessionOtherIncomeObj.OtherIncome >
                    250000
                      ? tax.totalTax
                      : 0) +
                      capitalGainTax +
                      cryptoTax
                ).toFixed(0)
              )
            : 0,
        taxSlab: tax.taxSlab,
      },
    });
  }, [
    tax,
    salary,
    businessProfessionOtherIncomeObj,
    capitalGainTax,
    cryptoTax,
  ]);

  useEffect(() => {
    if (
      getVersion(state?.state?.item?.appVersions || state?.state?.appVersions, state?.state?.item?.appVersion || state?.state?.appVersion) ===
      "1.0"
    ) {
      const totalIncome = convertNumberIntoINR(
        incomeData.total_income == null ? 0 : parseInt(incomeData.total_income)
      );

      // sub income list initialization
      const subIncomes = incomeData.income_details
        ? incomeData.income_details
            .sort((a, b) => a.rank - b.rank)
            .map((element) => {
              return {
                ...element,
                title:
                  INCOME_TYPES[element.type] ||
                  "Total " + element.type + " Income",
                value: convertNumberIntoINR(element.total_income),
              };
            })
            .filter((item) => item.value !== undefined)
        : [];

      // sub tax list initialization
      const subTaxes = calculatedTax
        .filter((item) => item.tax !== undefined)
        .map((element) => {
          return {
            ...element,
            title: TAX_TYPES[element.type] || "Tax on " + element.type,
            value: element.tax,
          };
        });

      // tax optimization value
      const taxSavingByExpert =
        targetedTax !== undefined && targetedTax.targeted_tax > 0
          ? convertNumberIntoINR(parseInt(targetedTax.targeted_tax))
          : HELPER_TEXTS.processing;

      // final list for nested list view
      const list = [
        {
          id: 0,
          title: "Total Annual Income",
          value: totalIncome,
          isSelected: false,
          subItems: [...subIncomes],
        },
        {
          id: 1,
          title: "Total Annual Tax",
          isSelected: false,
          value: convertNumberIntoINR(
            Math.round(subTaxes.reduce((acc, item) => acc + item.value, 0))
          ),
          subItems: [
            ...subTaxes.map((it) => {
              return {
                ...it,
                value: convertNumberIntoINR(Math.round(it.value)),
              };
            }),
          ],
        },
      ];

      setTransaction(list);
    } else {
      (async () => {
        const _data = await SuperSaverDashboardService.getTaxDetails(
          userId
        );
        const formattedData = cookDataForNestedListView(_data.data);
        setTargetedTax({
          ...targetedTax,
          targeted_tax: _data.data.targeted_tax,
        });

        setTransaction([...formattedData]);
      })();
    }
  }, [incomeData, calculatedTax]);

  //end

  const handlesetTargetedtax = (value) => {
    setTargetedtax(value);
  };
  const addUserId = () => {
    const params = new URLSearchParams();
    let query = userId;
    if (query) {
      params.set("user_id", query); // Use set() instead of append()
    } else {
      params.delete("user_id");
    }

    const queryString = params.toString();
    // Use the navigate function to update the URL with the new query string
    // navigate({ state: state.state, search: queryString });
    return queryString;
    // history.push({ search: params.toString() });
  };
  const handleClick = (url) => {
    const queryString = addUserId();

    navigate(
      {
        pathname: url,
        search: queryString,
      },
      {
        state: { ...state.state.item, ...userDetails },
      }
    );
  };

  const handleCross = () => {
    setShow(false);
  };

  const navigationArr = [
    {
      url: "/userProfileForAdvisor/incomeView",
      name: "income",
    },
    {
      url: "/userProfileForAdvisor/pending-advisorDoc",
      name: "Advisor Document",
    },
    {
      url: "/userProfileForAdvisor/deductionView",
      name: "Deduction",
    },
    {
      url: "/userProfileForAdvisor/exemption-page",
      name: "Exemption",
    },
    {
      url: "/userProfile-for-advisor/onboarding-form",
      name: "Onboarding Details",
    },
    {
      url: "/userProfile-for-advisor/client-chat",
      name: "Client Chat",
    },
    {
      url: "/userProfile-for-advisor/user-activity",
      name: "user-activity",
    },
    {
      url: "/userProfile-for-advisor/itr-filing",
      name: "ITR Filing",
    },
    {
      url: "/consultation-experience",
      name: "Consultation Experience",
    },
    {
      url: "/userProfileForAdvisor/notes",
      name: "Notes",
    },
    
  ];

  const handleGetIncome = async () => {
    const authToken = await getAuthToken();
    getUserIncome( authToken, userId).then((income) => {
      if (income?.income_details?.length === 0) {
        dispatch({
          type: actions.SET_INCOME,
          data: {},
        });
      } else {
        let newArr = income?.income_details?.map((d, i) => {
          if (d.type == "SALARIED") {
            return {
              ...d,
              rank: 1,
            };
          } else if (d.type === "CAPITAL_GAIN") {
            return {
              ...d,
              rank: 2,
              total_income:
                (d.total_profit_income ? d.total_profit_income : 0) -
                (d.total_loss_income ? d.total_loss_income : 0),
              type_details: d.type_details.map((d, i) => {
                return {
                  ...d,
                  total_income: d.total_profit_income,
                };
              }),
            };
          } else if (d.type === "OTHERS") {
            return { ...d, rank: 4 };
          } else {
            return { ...d, rank: 3 };
          }
        });

        income = {
          ...income,
          income_details: [...newArr],
        };
        dispatch({
          type: actions.SET_INCOME,
          data: income,
        });
      }
    });
  };

  const handleGetDeduction = async () => {
    getUserDocument(userId).then((deduction) => {
      let totalExemption = 0;
      for (
        let index = 0;
        index < deduction?.exemption_details?.exemption_details?.length;
        index++
      ) {
        const element = deduction?.exemption_details?.exemption_details[index];
        for (let index = 0; index < element?.type_details?.length; index++) {
          const newData = element?.type_details[index];
          totalExemption = totalExemption + newData?.exempted_amount;
        }
      }

      if (
        deduction?.section_details?.length !== 0 &&
        deduction?.exemption_details?.exemption_details?.length !== 0
      ) {
        dispatch({
          type: actions.SET_DEDUCTION,
          data:
            deduction?.section_details?.reduce((total, d) => {
              if (
                d?.max_investment_limit === 0 ||
                d?.max_investment_limit === undefined
              ) {
                return total + d?.grant_total_invested_amount;
              } else if (
                d?.grant_total_invested_amount > d?.max_investment_limit
              ) {
                return total + d?.max_investment_limit;
              } else {
                return total + d?.grant_total_invested_amount;
              }
            }, 0) + totalExemption,
        });
      } else {
        dispatch({
          type: actions.SET_DEDUCTION,
          data: 0,
        });
      }
      return deduction;
    });
  };

  useEffect(() => {
    setSalary(0);
    setBusinessProfessionOtherIncomeObj({
      BusinessProfessionIncome: 0,
      OtherIncome: 0,
      label: [],
    });
    setCryptoCurrency(0);
    setCapitalGainTax(0);
    setSTCG_DEBT_TOTAL_AMOUNT(0);
    setCapitalGainIncomeArr([]);
    setArrIncome([]);

    handleGetIncome();
    handleGetDeduction();
    handleGetIncome();
    handleGetDeduction();
  }, []);

  const getVersion = (appVersions, appVersion) => {
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    const details =
      appVersions && appVersions.find((it) => it.financial_year_id === fyId);
    return details !== undefined ? details.app_version : appVersion;
  };
  const handleViewBtn = (newList, value) => {
    const newObj = transaction[newList];
    const newArr = transaction;
    newArr.splice(newList, 1, { ...newObj, isSelected: value });
    setTransaction([...newArr]);
  };

  return loader ? (
    <LoaderPage />
  ) : (
    <>
      <UserProfileForAdvisorStyled>
        <ProfileStyle>
          {ExpireStatus === "EXPIRED" && (
            <div className="expireStrip">User Plan is Expired</div>
          )}
          <ProfileBox>
            <img
              src="https://prosperr-images.s3.ap-south-1.amazonaws.com/male_avatar.png"
              alt=""
            />
            <h1>{(state?.state?.item?.name) || (state?.state?.name)}</h1>
            <UserInfo>
              <h2>Id:</h2>
              <p>{(state?.state?.item?.referral_code) || (state?.state?.referral_code)}</p>
            </UserInfo>
            <UserInfo>
              <h2>Email:</h2>
              <p>{(state?.state?.item?.email) || (state?.state?.email)}</p>
            </UserInfo>
            {getAdvisorDetails(userDetails)}
            <UserInfo>
              <h2>CSR Name:</h2>
              <p>{(userDetails?.csr_user?.name)}</p>
            </UserInfo>
            <UserInfo>
              <h2>CSR Mobile:</h2>
              <p>{(userDetails?.csr_user?.mobile)}</p>
            </UserInfo>
            <UserInfo>
              <h2>Targeted Tax ({selectedFy.label}) :</h2>
              <p>{targeted_tax}</p>
              <span
                onClick={() => {
                  setShow(!show);
                }}
              >
                <EditBtn />
              </span>
            </UserInfo>
            <UserInfo style={{ width: "50%" }}>
              <NestedListView
                list={transaction}
                handleViewBtn={handleViewBtn}
              />
            </UserInfo>

            <UserInfo>
              <span
                style={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
              </span>
            </UserInfo>
          </ProfileBox>
          <ProfileBox>
            <h1>Plan Details</h1>
            <UserInfo>
              <h2>Plan type:</h2>
              <p>{(state?.state?.item?.service_type) || (state?.state?.service_type)} Plan</p>
            </UserInfo>
          </ProfileBox>
        </ProfileStyle>
      </UserProfileForAdvisorStyled>
      <PopUPCms width={40} left={250} show={show} handleCross={handleCross}>
        <PopupTargetTax
          user_id={userId}
          handleCross={handleCross}
          targeted_tax={targeted_tax}
          handlesetTargetedtax={handlesetTargetedtax}
          selectedFy={selectedFy}
          targetedTaxExternalId={targetedTaxExternalId}
          handleDropDownChange={handleFYChange}
        />
      </PopUPCms>
      <Backdrop onHideModelClick={handleCross} show={show} />
    </>
  );
};

const getAdvisorDetails = (userDetails) => {
  const advisorDetails = userDetails?.assigned_advisor_details || [];
  const secondaryAdvisor = advisorDetails?.find(advisor => advisor?.type === 'SECONDARY');
  const primaryAdvisor = advisorDetails?.find(advisor => advisor?.type === 'PRIMARY');
  const currentLoginUser = JSON.parse(localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER));
  const isPrimaryAdvisor = primaryAdvisor?.advisor_id === currentLoginUser?.user_roll_id;

  return (
    <UserInfo>
      <h2>{isPrimaryAdvisor ? 'Secondary' : 'Primary'} Advisor:</h2>
      <p>{isPrimaryAdvisor ? secondaryAdvisor?.name || "Not-Assigned" : primaryAdvisor?.name || "Not-Assigned"}</p>
    </UserInfo>
  );
};

export default LayoutHOC(UserProfileForAdvisor);

import React, {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import toast, { Toaster } from "react-hot-toast";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {MdContentCopy} from "react-icons/md";
import {
  assignAdvisor,
  assignCSR,
  getadvisor,
  getCsr,
  getSuperSaverUser,
  getTargetTax,
  inviteUser,
  SuperSaverDashboardService,
} from "../../Services/SuperSaver";
import {getAuth} from "firebase/auth";
import Alert1 from "../../commonComponents/Alert/Alert1";
import {useEffect} from "react";
import {Main, ClientDetails} from "./SuperSaverUserProfile.styled";
import LoaderPage from "../../commonComponents/LoaderPage";
import PopUPCms from "../../commonComponents/PopUPCms";
import Backdrop from "../../commonComponents/PopUPCms/BackDrop";
import PopupTargetTax from "../../components/PopupTargetTax";
import {
  getUpdatedTax,
  setCapitalGainTaxFn,
  setIncomeAndTaxFn,
} from "../AssignedUsers/components/UserProfileForAdvisor/utils";
import {getTaxBySalary} from "../../utils/taxCalc";
import {useDispatch, useSelector} from "react-redux";
import {GetCRYPTO_CURRENCYTax} from "../../utils/capitalGainTaxCalc";
import {actions} from "../AssignedUsers/components/UserProfileForAdvisor/store/action";
import {convertNumberIntoINR} from "../../utils/ConvertNumnerIntoIndianCurrency";
import {getAuthToken} from "../../Services/SuperSaverClientChat";
import {LOCAL_DB_KEYS} from "../../constants/localStorage";
import {cookDataForNestedListView} from "../../utils/taxUtils";
import UserSideBar from "../../components/UserSideBar";
import UserActivity from "./Components/UserActivity";
import {SuperSaverUserProfileApi} from "../../Services/SuperSaverUserProfileService";
import PaymentComponent from "./Components/PaymentComponent";
import AdvisorSelection from "./Components/AdvisorSelection";
import CsrSelection from "./Components/CsrSelection";
import TargetTaxComponent from "./Components/TargetTaxComponent";
import {UserInfo} from "../AssignedUsers/components/UserProfileForAdvisor/UserProfileForAdvisor.styled";
import NestedListView from "../../components/NestedListView";
import {getVersion, handleGetDeduction, handleGetIncome} from "./utils";
import {rowFunction} from "../SuperSaverUser/utils";
import {getHeaders} from "../../Services/httpUtil";
import {objectEmptyValidater} from "../../utils/validate";
import LayoutHOC from "../../components/UserSideBar";
import EditBtn from "../../commonComponents/EditBtn";
import UserPlanDegradation from "../../components/UserPlanDegradation";
import {Actions} from "../../Routes/store/action";
import { useQuery } from "../../utils/routeUtils";

const HELPER_TEXTS = {
  expertTaxTitle: "Optimized Tax by Expert ",
  processing: "Processing...",
  projectTaxDateText:
    "Note: Final Income Tax will be generated on Mar 31, 2023.",
};

const INCOME_TYPES = {
  SALARIED: "Total Salary Income",
  CAPITAL_GAIN: "Total Capital Gain Income",
  BUSINESS_PROFESSION: "Total Business Profession Income",
  CRYPTO_CURRENCY: "Total Crypto Currency Income",
  OTHERS: "Other Incomes",
};

const TAX_TYPES = {
  "Salary,Business & Profession,Other":
    "Tax on Salary, Business & Profession, Others",
  "Business & Profession,Other": "Tax on Business & Profession, Other",
  "Capital Gain": "Tax on Capital Gain",
  "Crypto Currency": "Tax on Crypto Currency",
};

const SuperSaverUserProfile = () => {
  const params = useParams();
  const planUpgradePopupStatus = useSelector(
    (state) => state.cmsReducer.planUpgradePopupStatus
  );
  const userIdFromQueryParam = useQuery().get("user_id") || undefined;
  const userIdFromURL = params.userId;
  const UserID = userIdFromURL !== undefined ? userIdFromURL : userIdFromQueryParam;
  const [isCopied, setIsCopied] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const [userActivities, setUserActivities] = useState(null);
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [open, setopen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [targeted_tax, setTargetedtax] = useState("To Be Calculated.");
  const [generatedStateData, setGeneratedStateData] = useState();
  const [userId, setUserId] = useState(UserID);
  const [checked, setChecked] = useState();
  const [targetedTaxExternalId, setTargetedTaxExternalId] = useState();
  const [alertMsg, setAlertMsg] = useState("");
  const [advArr, setAdvArr] = useState([]);
  const [csrArr, setCsrArr] = useState([]);
  const [advisorId, setAdvisorId] = useState("");
  const [csrId, setCsrId] = useState("");
  const [reInvitation, setReInvitation] = useState(false);
  const fyObj = useSelector((state) => state.LayoutReducer.F_Y_OBJ);
  const [selectedFy, setSelectedFy] = useState({});
  const [secondaryAdvisorId, setSecondaryAdvisorId] = useState(null);
  const [clientArray, setClientArray] = useState([]);
  useEffect(() => {
    (async () => {
      const data = await getTargetTax(userId);
      setTargetedtax(
        data.targeted_tax !== undefined
          ? data.targeted_tax
          : "To Be Calculated."
      );
      setTargetedTaxExternalId(data.external_id);
      const userActivities =
        await SuperSaverDashboardService.getUsersActivityData(userId);
      setUserActivities(
        userActivities &&
          userActivities.data &&
          userActivities.data.activity_details
      );
    })();
  }, []);

  const incomeData = useSelector((state) => state.TaxReducer.incomeArray);
  const totalDeduction = useSelector((state) => state.TaxReducer.deduction);

  const [salary, setSalary] = useState(0);
  const [targetedTax, setTargetedTax] = useState({});
  const [capitalGainIncomeArr, setCapitalGainIncomeArr] = useState([]);
  const [STCG_DEBT_TOTAL_AMOUNT, setSTCG_DEBT_TOTAL_AMOUNT] = useState(0);
  const [capitalGainTax, setCapitalGainTax] = useState(0);
  const [cryptoCurrency, setCryptoCurrency] = useState(0);
  const [
    businessProfessionOtherIncomeObj,
    setBusinessProfessionOtherIncomeObj,
  ] = useState({
    BusinessProfessionIncome: 0,
    OtherIncome: 0,
    label: [],
  });
  const dispatch = useDispatch();
  const [tax, settax] = useState(
    getTaxBySalary(
      salary,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
        businessProfessionOtherIncomeObj.OtherIncome
    )
  );
  const [transaction, setTransaction] = useState([]);
  const [calculatedTax, setCalculatedTax] = useState([]);

  const [arrIncome, setArrIncome] = useState([]);
  const [taxObj, setTaxObj] = useState(
    getTaxBySalary(
      salary,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
        businessProfessionOtherIncomeObj.OtherIncome
    )
  );
  useEffect(() => {
    handleFYChange({
      label: fyObj.financialYear,
      value: fyObj.financialYearId,
    });
  }, [fyObj]);

  const handleFYChange = (value) => {
    setSelectedFy(value);
  };

  useEffect(() => {
    let taxObj = getTaxBySalary(
      salary,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
        businessProfessionOtherIncomeObj.OtherIncome
    );

    setTaxObj({...taxObj});
  }, [
    salary,
    STCG_DEBT_TOTAL_AMOUNT,
    totalDeduction,
    businessProfessionOtherIncomeObj.BusinessProfessionIncome,
    businessProfessionOtherIncomeObj.OtherIncome,
  ]);

  const handleChange = (event) => {
    if (checked) {
    } else {
      setopen(true);
    }
  };

  useEffect(() => {
    setCapitalGainTaxFn(
      salary,
      businessProfessionOtherIncomeObj,
      settax,
      totalDeduction,
      STCG_DEBT_TOTAL_AMOUNT,
      arrIncome,
      setTargetedTax,
      setCapitalGainTax,
      capitalGainIncomeArr,
      incomeData,
      incomeData.income_details
    );
  }, [
    salary,
    businessProfessionOtherIncomeObj.BusinessProfessionIncome,
    businessProfessionOtherIncomeObj.OtherIncome,
    totalDeduction,
    STCG_DEBT_TOTAL_AMOUNT,
    capitalGainIncomeArr,
    incomeData,
    arrIncome,
    incomeData.income_details,
  ]);

  const incomeTypes = {
    SALARIED: "SALARIED",
    CAPITAL_GAIN: "CAPITAL_GAIN",
    STCG_DEBT: "STCG_DEBT",
    CRYPTO_CURRENCY: "CRYPTO_CURRENCY",
  };

  const setBusinessProfessionOtherIncomeObjFn = (value) => {
    setBusinessProfessionOtherIncomeObj(value);
  };

  useEffect(() => {
    setIncomeAndTaxFn({
      incomeData: incomeData,
      incomeTypes: incomeTypes,
      setSalary: setSalary,
      setCapitalGainIncomeArr: setCapitalGainIncomeArr,
      setSTCG_DEBT_TOTAL_AMOUNT: setSTCG_DEBT_TOTAL_AMOUNT,
      arrIncome: arrIncome,
      setCryptoCurrency: setCryptoCurrency,
      businessProfessionOtherIncomeObj: businessProfessionOtherIncomeObj,
      setBusinessProfessionOtherIncomeObj:
        setBusinessProfessionOtherIncomeObjFn,
    });
  }, [incomeData, incomeData.income_details]);

  useEffect(() => {
    const tax =
      taxObj &&
      getUpdatedTax(
        businessProfessionOtherIncomeObj,
        salary,
        totalDeduction,
        STCG_DEBT_TOTAL_AMOUNT,
        arrIncome,
        taxObj
      );

    setCalculatedTax([...tax]);
  }, [
    businessProfessionOtherIncomeObj.BusinessProfessionIncome,
    businessProfessionOtherIncomeObj.OtherIncome,
    businessProfessionOtherIncomeObj.label,
    salary,
    incomeData,
    arrIncome,
    taxObj,
  ]);

  let cryptoTax = GetCRYPTO_CURRENCYTax(cryptoCurrency);

  useEffect(() => {
    dispatch({
      type: actions.SET_CURRENT_TAX,
      data: {
        currentTax:
          parseInt(
            (tax == undefined
              ? 0
              : tax &&
                (salary +
                  businessProfessionOtherIncomeObj.BusinessProfessionIncome +
                  businessProfessionOtherIncomeObj.OtherIncome >
                250000
                  ? tax.totalTax
                  : 0) +
                  capitalGainTax +
                  cryptoTax
            ).toFixed(0)
          ) > 12500
            ? parseInt(
                (tax == undefined
                  ? 0
                  : tax &&
                    (salary +
                      businessProfessionOtherIncomeObj.BusinessProfessionIncome +
                      businessProfessionOtherIncomeObj.OtherIncome >
                    250000
                      ? tax.totalTax
                      : 0) +
                      capitalGainTax +
                      cryptoTax
                ).toFixed(0)
              )
            : 0,
        taxSlab: tax.taxSlab,
      },
    });
  }, [
    tax,
    salary,
    businessProfessionOtherIncomeObj,
    capitalGainTax,
    cryptoTax,
  ]);

  useEffect(() => {
    if (
      getVersion(
        generatedStateData && generatedStateData?.appVersions,
        generatedStateData?.appVersion
      ) === "1.0"
    ) {
      const totalIncome = convertNumberIntoINR(
        incomeData.total_income == null ? 0 : parseInt(incomeData.total_income)
      );

      // sub income list initialization
      const subIncomes = incomeData.income_details
        ? incomeData.income_details
            .sort((a, b) => a.rank - b.rank)
            .map((element) => {
              return {
                ...element,
                title:
                  INCOME_TYPES[element.type] ||
                  "Total " + element.type + " Income",
                value: convertNumberIntoINR(element.total_income),
              };
            })
            .filter((item) => item.value !== undefined)
        : [];

      // sub tax list initialization
      const subTaxes = calculatedTax
        .filter((item) => item.tax !== undefined)
        .map((element) => {
          return {
            ...element,
            title: TAX_TYPES[element.type] || "Tax on " + element.type,
            value: element.tax,
          };
        });

      // tax optimization value
      const taxSavingByExpert =
        targetedTax !== undefined && targetedTax.targeted_tax > 0
          ? convertNumberIntoINR(parseInt(targetedTax.targeted_tax))
          : HELPER_TEXTS.processing;

      // final list for nested list view
      const list = [
        {
          id: 0,
          title: "Total Annual Income",
          value: totalIncome,
          subItems: [...subIncomes],
        },
        {
          id: 1,
          title: "Total Annual Tax",
          value: convertNumberIntoINR(
            Math.round(subTaxes.reduce((acc, item) => acc + item.value, 0))
          ),
          subItems: [
            ...subTaxes.map((it) => {
              return {
                ...it,
                value: convertNumberIntoINR(Math.round(it.value)),
              };
            }),
          ],
        },
      ];

      setTransaction(list);
    } else {
      generatedStateData &&
        (async () => {
          const _data = await SuperSaverDashboardService.getTaxDetails(userId);
          const formattedData = cookDataForNestedListView(_data.data);
          setTransaction([...formattedData]);
        })();
    }
  }, [
    incomeData,
    calculatedTax,
    generatedStateData?.appVersions,
    generatedStateData?.appVersion,
  ]);

  const handleMsg = (msg) => {
    setAlertMsg(msg);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  const handleCross = () => {
    setShow(false);
  };

  const handlesetTargetedtax = (value) => {
    setTargetedtax(value);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  useEffect(() => {

    const modifiedClientArray = [
      {
        placeholder: "Name",
        value: userDetails?.user_response?.first_name,
      },
      {
        placeholder: "Mobile No",
        value: userDetails?.user_response?.mobile,
      },

      {
        placeholder: "Email",
        value: userDetails?.user_response?.email,
      },
      {
        placeholder: "Category type",
        value: userDetails?.category_type,
        isEditable: true,
      }
    ];
    setClientArray(modifiedClientArray);
  }, [userDetails]);

  const assignExpert = (req) => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          assignAdvisor(data, req).then((da) => {
            da.advisor_id.length > 0 &&
              handleMsg("Advisor Updated Successfully");
          });
        });
      }
    });
  };

  const getAdvisorList = () => {
    const {Authorization: token} = getHeaders();
    getadvisor(token).then(function (value) {
      var arr = value.map((d, i) => {
        return {
          label: d.user_details.first_name + " " + d.user_details.last_name,
          advisorId: d.user_details.external_id,
        };
      });
      setAdvArr(arr);
    });
  };

  const getCsrList = () => {
    try {
      const {Authorization: token} = getHeaders();
      getCsr(token).then(function (value) {
        const arr = value.map((d) => {
          return {
            label: d?.name,
            csrId: d?.id,
            mobile: d?.mobile
          };
        });
        setCsrArr(arr);
      });
    } 
    catch (error) {
      toast.error(error ? error : error.message);
    }
  };

  useEffect(() => {
    setSalary(0);
    setBusinessProfessionOtherIncomeObj({
      BusinessProfessionIncome: 0,
      OtherIncome: 0,
      label: [],
    });
    setCryptoCurrency(0);
    setCapitalGainTax(0);
    setSTCG_DEBT_TOTAL_AMOUNT(0);
    setCapitalGainIncomeArr([]);
    setArrIncome([]);

    handleGetIncome(userId, dispatch);
    handleGetDeduction(userId, dispatch);
    getAdvisorList();
    getCsrList();
  }, []);

  const inviteUserCall = () => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          inviteUser(data, userId);
          handleMsg("User invited successfully");
          setReInvitation(true);
        });
      }
    });
  };

  const handleViewBtn = (newList, value) => {
    const newObj = transaction[newList];
    const newArr = transaction;
    newArr.splice(newList, 1, {...newObj, isSelected: value});
    setTransaction([...newArr]);
  };

  const handleUserId = (id) => {
    setUserId(id);
  };

   const getUserDetails = async () => {
    const fYId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    const response =
      UserID &&
      (await SuperSaverUserProfileApi.getUserDetailsByUserId(UserID, fYId));
    const userDetails = await response.data;
    setUserDetails(userDetails);
    const paymentStatus = userDetails?.user_payment_details?.payment_type;

    setChecked(
      paymentStatus
        ? paymentStatus === "FULL_PAYMENT" ||
            paymentStatus === "PARTIAL_PAYMENT"
        : false
    );
    const secondaryAdvId = userDetails?.assigned_advisor_details?.filter(
      (d) => d.type === "SECONDARY"
    )[0]?.advisor_id;
    secondaryAdvId && setSecondaryAdvisorId(secondaryAdvId);
    const primaryAdvId = userDetails?.assigned_advisor_details?.filter(
      (d) => d.type === "PRIMARY"
    )[0]?.advisor_id;
    userDetails?.assigned_advisor_details?.length > 0 && setAdvisorId(primaryAdvId);
    setReInvitation(userDetails?.invitation_status !== "INVITED");
    setGeneratedStateData(rowFunction(userDetails, 1, true));
    setCsrId(userDetails?.csr_user?.id);
    setLoader(false);
  };

  useEffect(() => {
    getUserDetails();
  }, [UserID, checked, planUpgradePopupStatus]);
  const paymentStatus =    userDetails?.user_payment_details?.payment_type||userDetails?.subscription_status;
  const isToggleCheckActive=
  paymentStatus? paymentStatus === "FULL_PAYMENT" ||
  paymentStatus === "PAID" ||
      paymentStatus === "PARTIAL_PAYMENT"
  : false;
  const paymentProps = {
    paymentStatus:isToggleCheckActive,
    handleChange,
    checked,
    open,
    setopen,
    handleMsg,
    setChecked,
    userDetails,
    userId,
    handleUserId,
    getUserDetails,
  };
  const handleAdvisorUpdate = (e) => {
    const {name, value = "undefined"} = e.target;

    let userName = localStorage.getItem("user");
    const obj = {
      user_id: userId,

      ...(advisorId?.length > 0 && {advisor_id: advisorId}),
      ...(secondaryAdvisorId?.length > 0 && {
        secondary_advisor_id: secondaryAdvisorId,
      }),
      [name]: value,
      assigned_by: JSON.parse(userName).displayName,
    };
    e.target.value !== "" && assignExpert(obj);
    name === "advisor_id" && setAdvisorId(e.target.value);
    name === "secondary_advisor_id" && setSecondaryAdvisorId(e.target.value);
  };

  const handleCsrUpdate = (e) => {
    const {name, value} = e.target;
    const obj = {
      user_id: userId,
      ...(csrId?.length > 0 && {csr_id: csrId}),
      [name]: value
    };
    if(value !== "" ){
      assignCSR(obj).then((data) => {
      setCsrId(data?.csr_user?.id);
      });
    }
  };
  const AdvisorSelectionProps = {
    handleAdvisorUpdate,
    userId,
  };
  const CsrSelectionProps = {
    handleCsrUpdate,
    userId,
  };
  const TargetTaxComponentProps = {
    selectedFy,
    targeted_tax,
    show,
    setShow,
    userId,
    onCopyText,
    isCopied,
  };
  const toggleBackdrop = () => {
    dispatch({
      type: Actions.SET_PLAN_UPGRADE_POPUP_STATUS,
      data: !planUpgradePopupStatus,
    });
  };
  const paymentFormProps = {
    paymentStatus,
    handleChange,
    checked,
    open,
    setopen,
    handleMsg,
    setChecked,
    userDetails,
    userId,
    handleUserId,
  };
  const planUpgradeProps = {
    toggleBackdrop,
    showPopup: planUpgradePopupStatus,
    paymentFormProps,
    category: userDetails?.category_type,
    userDetails,
    checked,
    userId,
  };

  return loader ? (
    <LoaderPage />
  ) : (
    <Main>
      <Alert1 ShowAlert={showAlert} mid={true} SubmitMsg={alertMsg} />
      <UserSideBar item={rowFunction(userDetails, 1, true)} userId={userId} />
      <div className="ClientWrap">
        {userDetails?.subscription_status === "EXPIRED" && (
          <div className="expireStrip">User Plan is Expired</div>
        )}
        {clientArray.map((data, i) => (
          <div className="clientBox" key={i}>
            <div className="ClientHed">{data.placeholder} </div>
            <ClientDetails
              className="ClientDetails"
              style={data?.isEditable && {justifyContent: "space-between"}}
            >
              <div className="data">{data.value}</div>
              {data?.value &&
                data?.isEditable &&
                (checked ? data.value !== "ELITE" : true) &&
                userDetails.product !== "ITR_FILING" && (
                  <span
                    onClick={() => {
                      toggleBackdrop();
                      const value = 0;
                      dispatch({
                        type: Actions.SET_PLAN_UPGRADE_POPUP_STEPS,
                        data: value,
                      });
                    }}
                  >
                    <EditBtn />
                  </span>
                )}
              <CopyToClipboard
                className="copyIcon"
                text={data.value}
                onCopy={onCopyText}
              >
                <span>{isCopied ? "Copied!" : <MdContentCopy />}</span>
              </CopyToClipboard>
            </ClientDetails>
          </div>
        ))}

        <PaymentComponent {...paymentProps} />

        <AdvisorSelection
          {...AdvisorSelectionProps}
          title={"Primary Advisor"}
          name={"advisor_id"}
          advisorId={advisorId}
          advArr={
            advArr.filter((d) => {
              return d.advisorId !== secondaryAdvisorId;
            }) || []
          }
        />
        {advisorId?.length > 0 && (
          <AdvisorSelection
            {...AdvisorSelectionProps}
            title={"Secondary Advisor"}
            name={"secondary_advisor_id"}
            advArr={
              advArr.filter((d) => {
                return d.advisorId !== advisorId;
              }) || []
            }
            advisorId={secondaryAdvisorId}
          />
        )}
        <CsrSelection
          {...CsrSelectionProps}
          title={"CSR Details"}
          name={"csr_id"}
          csrId={csrId}
          csrArr={csrArr}
        />
        <TargetTaxComponent {...TargetTaxComponentProps} />
        <div
          className="clientBox"
          style={{
            border: "none",
          }}
        >
          {!reInvitation &&
            userId.length > 0 && (
              <button className="btn open-new" onClick={inviteUserCall}>
                Invite
              </button>
            )}
        </div>

        <UserInfo
          style={{
            width: "80%",
            textAlign: "center",
            padding: "0 34px",
          }}
        >
          <NestedListView list={transaction} handleViewBtn={handleViewBtn} />
        </UserInfo>
      </div>
      <UserActivity userActivities={userActivities} userId={userId} />
      <PopUPCms width={40} left={250} show={show} handleCross={handleCross}>
        <PopupTargetTax
          user_id={userId}
          handleCross={handleCross}
          targeted_tax={targeted_tax}
          handlesetTargetedtax={handlesetTargetedtax}
          selectedFy={selectedFy}
          targetedTaxExternalId={targetedTaxExternalId}
          handleDropDownChange={handleFYChange}
        />
      </PopUPCms>
      <Backdrop onHideModelClick={handleCross} show={show} />
      <UserPlanDegradation {...planUpgradeProps} />
    </Main>
  );
};

export default LayoutHOC(SuperSaverUserProfile);
